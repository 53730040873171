<template>
  <div class="our-clients">
    <div class="our-clients-info">
      <h1 style="color: white">Client Testimonials</h1>

      <p style="color: white">See what our clients have to say</p>

      <div class="testimonial-cards forWeb">
        <!-- COMEDY MANILA -->
        <div>
          <img src="img/quote-icon.png" class="quote" />
          <p>
            We made the right choice to work with HelixPay when we decided to
            bring back in-person shows for Comedy Manila.
          </p>
          <div class="client-information">
            <img
              src="img/ticket-img-icon-comedy-manila.png"
              class="client-img"
            />
            <div>
              <b> Comedy Manila </b>
              <p>Leading Comedy Show Producer</p>
            </div>
          </div>
        </div>

        <!-- MNL48 -->
        <div>
          <img src="img/quote-icon.png" class="quote" />
          <p>
            I am impressed with how HelixPay enabled us to sell tickets without
            having to go through so much manual work, like how we did it before
            with Google forms.
          </p>
          <div class="client-information">
            <img src="img/ticket-img-icon-mnl48.png" class="client-img" />
            <div>
              <b> MNL48 </b>
              <p>Popular all-girl sing & dance group</p>
            </div>
          </div>
        </div>

        <!-- PODCAST NETWORK ASIA -->
        <div>
          <img src="img/quote-icon.png" class="quote" />
          <p>
            We especially appreciate their new ticketing feature as it has
            helped us create a more seamless process for both the buyers and for
            us on the backend as well.
          </p>
          <div class="client-information">
            <img src="img/ticket-img-icon-pna.png" class="client-img" />
            <div>
              <b> Podcast Network Asia </b>
              <p>The biggest podcast producer in the Philippines</p>
            </div>
          </div>
        </div>

        <!-- THOC -->
        <div>
          <img src="img/quote-icon.png" class="quote" />
          <p>
            With helixpay, the entire process was streamlined and convenient -
            setting up the website was a breeze and the support that came along
            with it from the HelixPay team was outstanding.
          </p>
          <div class="client-information">
            <img src="img/ticket-img-icon-thoc.png" class="client-img" />
            <div>
              <b> The House of Collab </b>
              <p>Leading digitally native reality show</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.our-clients {
  width: 100%;
  background-color: black;
}

.our-clients-info {
  max-width: 71rem;
  padding: 6rem 2rem;
  margin: auto;
}

.our-clients-info h1 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.our-clients-info p {
  text-align: center;
  font-weight: 600;
  margin-top: 1rem;
  margin: 0;
}

.testimonial-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.testimonial-cards > div {
  display: grid;
  gap: 1.5rem;
  padding: 3rem;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.testimonial-cards > div p {
  font-weight: 500;
  opacity: 0.5;
}

.quote {
  width: 8%;
  opacity: 0.7;
  display: block;
  margin: auto;
  margin-bottom: 1rem;
}

.client-information {
  width: fit-content;
  margin: auto;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-top: 2rem;
}

.client-img {
  width: 4rem;
}

.client-information div p {
  text-align: left;
}

@media only screen and (max-width: 800px) {
  .testimonial-cards {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .our-clients-info h1 {
    font-size: 2rem;
  }
}
</style>
