<template>
  <section>
    <div class="title">
      <h1>Powering the largest events in the country</h1>
    </div>

    <div class="clients">
      <div class="first tape">
        <div v-for="item in rows[0]" :key="item.image" class="details">
          <VLazyImage
            :src="item.image"
            :alt="item.alt"
            :class="{ rounded: item.rounded }"
          />
        </div>
      </div>

      <div class="second tape">
        <div v-for="item in rows[1]" :key="item.image" class="details">
          <VLazyImage
            :src="item.image"
            :alt="item.alt"
            :class="{ rounded: item.rounded }"
          />
        </div>
      </div>

      <div class="first tape">
        <div v-for="item in rows[2]" :key="item.image" class="details">
          <VLazyImage
            :src="item.image"
            :alt="item.alt"
            :class="{ rounded: item.rounded }"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { times } from 'lodash-es';
import VLazyImage from 'v-lazy-image';

function multiply(value) {
  return times(5, () => value).reduce(
    (prev, current) => prev.concat(current),
    []
  );
}

const rows = [
  multiply([
    {
      image: '/img/used-by/warner-music-group.png',
      alt: 'Warner Music Group',
    },
    {
      image: '/img/used-by/conquest.png',
      alt: 'Conquest PH',
    },
    {
      image: '/img/used-by/summit-media.png',
      alt: 'Summit Media',
    },
    {
      image: '/img/used-by/esquire.png',
      alt: 'Esquire',
    },
    {
      image: '/img/used-by/comedy-manila.png',
      alt: 'Comedy Manila',
    },
    {
      image: '/img/used-by/bonifacio-high-street.png',
      alt: 'Bonifacio High Street',
    },
  ]),

  multiply([
    {
      image: '/img/used-by/business-world.png',
      alt: 'Business World',
    },
    {
      image: '/img/used-by/pgdx.png',
      alt: 'PGDX',
    },
    {
      image: '/img/used-by/rekta-sa-kalye.jpeg',
      alt: 'Rekta sa Kalye',
      rounded: true,
    },
    {
      image: '/img/used-by/cosmopolitan.png',
      alt: 'Cosmopolitan',
    },
    {
      image: '/img/used-by/diageo.png',
      alt: 'Diageo',
    },
    {
      image: '/img/used-by/bomb-vinos.png',
      alt: 'Bomb Vinos',
    },
  ]),

  multiply([
    {
      image: '/img/used-by/draper-startup-house.png',
      alt: 'Draper Startup House',
    },
    {
      image: '/img/used-by/careless-music.png',
      alt: 'Careless Music',
      rounded: true,
    },
    {
      image: '/img/used-by/icap.png',
      alt: 'ICAP',
    },
    {
      image: '/img/used-by/unknwn.png',
      alt: 'Unknwn',
      rounded: true,
    },
    {
      image: '/img/used-by/good-gang.png',
      alt: 'Good Gang',
    },
    // {
    //   image: '/img/used-by/amcham.png',
    //   alt: 'AMCHAM',
    // },
  ]),

  multiply([]),
];
</script>

<style scoped>
section {
  width: 100%;
}

.clients {
  width: 100%;
  margin: 3rem auto 5rem auto;
  padding: 1rem 0;
  display: grid;
  grid-auto-columns: 1fr;
  overflow: hidden;
  gap: 1rem;
}

.title {
  padding: 4rem 2rem 0 2rem;
  max-width: 71rem;
  margin: auto;
}

.title h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 1rem;
}

.details {
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: 0.2s ease-in;
}

.details img {
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.rounded {
  border-radius: 10px;
  overflow: hidden;
}

.details p {
  display: none;
}

.tape {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  margin: auto;
  gap: 3rem;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ FIRST */

.first {
  animation: left 350s infinite;
}

@keyframes left {
  0% {
    transform: translateX(-100rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ SECOND */

.second {
  animation: right 350s infinite;
}

@keyframes right {
  0% {
    transform: translateX(0rem);
  }
  100% {
    transform: translateX(-100rem);
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ SECOND */

@media only screen and (max-width: 750px) {
  .details {
    width: 7rem;
    grid-template-columns: 1fr;
  }

  .tape {
    gap: 1.5rem;
  }

  .clients {
    gap: 0.5rem;
  }

  .details img {
    width: 100%;
    margin: auto;
  }

  @keyframes left {
    0% {
      transform: translateX(-220rem);
    }
    100% {
      transform: translateX(0rem);
    }
  }
}

@media only screen and (max-width: 650px) {
  .title h1 {
    font-size: 2rem;
  }
}
</style>
