<template>
  <div class="our-clients">
    <div class="our-clients-info">
      <h1>
        Full Featured <br />
        Ticketing System
      </h1>

      <p>Take a tour of our most popular features</p>

      <div class="ticketing-features">
        <div
          :class="{ active: layout === 'create' }"
          @click="layout = 'create'"
        >
          <VLazyImage src="/img/create-icon.png" />
          <p>Create Your Ticket</p>
        </div>
        <div :class="{ active: layout === 'boost' }" @click="layout = 'boost'">
          <VLazyImage src="/img/sf-sales-icon.png" />
          <p>Sell Tickets</p>
        </div>
        <div
          :class="{ active: layout === 'qrcode' }"
          @click="layout = 'qrcode'"
        >
          <VLazyImage src="/img/admin-icon.png" />
          <p>Custom QR Code</p>
        </div>
        <div :class="{ active: layout === 'scan' }" @click="layout = 'scan'">
          <VLazyImage src="/img/scan-icon.png" />
          <p>Scan Feature</p>
        </div>
        <div
          :class="{ active: layout === 'secure' }"
          @click="layout = 'secure'"
        >
          <VLazyImage src="/img/secure-icon-img.png" />
          <p>Secure Your Tickets</p>
        </div>
      </div>

      <!-- CREATE -->
      <div v-if="layout === 'create'" class="feature">
        <VLazyImage src="/img/create-img.png" class="main-img-mobile" alt="" />

        <div class="left-part">
          <h5>Create your ticket</h5>

          <h1>Build Your Brand to Enhance Event Visibility</h1>

          <p>
            Reflect your branding by customizing your ticket design, and ticket
            tiers (Basic, VIP, etc.).
          </p>

          <a :href="EVENTS_DOCS_URL" class="learn-more"> Learn more </a>
        </div>

        <div class="right-part">
          <VLazyImage src="/img/create-img.png" class="main-img-web" alt="" />
        </div>
      </div>

      <!-- BOOST -->
      <div v-if="layout === 'boost'" class="feature">
        <VLazyImage
          src="/img/ticketing-main-image.webp"
          class="main-img-mobile"
          alt=""
        />

        <div class="left-part">
          <h5>Sell Tickets</h5>

          <h1>Boost your Ticket Sales</h1>

          <p>
            Sell these tickets from a custom designed ecommerce website for your
            brand or event.
          </p>

          <a :href="EVENTS_DOCS_URL" class="learn-more"> Learn more </a>
        </div>

        <div class="right-part">
          <VLazyImage
            src="/img/ticketing-main-image.webp"
            class="main-img-web"
            alt=""
          />
        </div>
      </div>

      <!-- QRCODE -->
      <div v-if="layout === 'qrcode'" class="feature">
        <VLazyImage src="/img/qrcode-img.png" class="main-img-mobile" alt="" />

        <div class="left-part">
          <h5>Custom QR Code</h5>

          <h1>Receive ticket immediately</h1>

          <p>
            Customers enjoy a seamless flow from purchase to automatically
            receiving a secure ticket with unique QR code.
            <br />
            No need to print anything!
          </p>

          <a :href="EVENTS_DOCS_URL" class="learn-more"> Learn more </a>
        </div>

        <div class="right-part">
          <VLazyImage src="/img/qrcode-img.png" class="main-img-web" alt="" />
        </div>
      </div>

      <!-- SCAN FEATURE -->
      <div v-if="layout === 'scan'" class="feature">
        <VLazyImage src="/img/scan-image.webp" class="main-img-mobile" alt="" />

        <div class="left-part">
          <h5>Easy Ticket Scanning</h5>

          <h1>Scan Tickets at the Event</h1>

          <p>
            Merchant can create multiple access roles like “Event Manager” and
            basic “Ticket Scanner” to easily scan and track event attendees.
          </p>

          <a :href="EVENTS_DOCS_URL" class="learn-more"> Learn more </a>
        </div>

        <div class="right-part">
          <VLazyImage src="/img/scan-image.webp" class="main-img-web" alt="" />
        </div>
      </div>

      <!-- SECURE TICKETS -->
      <div v-if="layout === 'secure'" class="feature">
        <VLazyImage src="/img/secure-img.png" class="main-img-mobile" alt="" />

        <div class="left-part">
          <h1>Tickets are Secure & Single-Use</h1>

          <p>
            QR Code tickets are dtigitally secure. Anti-hoarding tools like
            ‘limited quantity per order’ are available as well.
          </p>

          <a :href="EVENTS_DOCS_URL" class="learn-more"> Learn more </a>
        </div>

        <div class="right-part">
          <VLazyImage src="/img/secure-img.png" class="main-img-web" alt="" />
        </div>
      </div>

      <!-- MERCH -->
      <!-- <div class="feature" v-if="layout === 'merch'">

            <VLazyImage
              src="/img/scan-image.webp"
              class="main-img-mobile"
              alt=""
              >

            <div class="left-part">
              <h1>
                Sell Merch for the Event
              </h1>

              <p>
                Easily sell "add-ons" to the ticket purchase
                experience like merch or special extra event access.
              </p>

                <a
                  :href="EVENTS_DOCS_URL"
                  class="learn-more"
                  >
Learn more </a
                >
            </div>

            <div class="right-part">

              <VLazyImage
                src="/img/scan-image.webp"
                class="main-img-web"
                alt=""
                >

            </div>

        </div> -->
    </div>
  </div>
</template>

<script setup>
import { EVENTS_DOCS_URL } from '@/constants';
import VLazyImage from 'v-lazy-image';
import { ref } from 'vue';

const layout = ref('scan');
</script>

<style scoped>
.main-img-mobile {
  display: none;
}

#mobileOnly {
  display: none;
}

.our-clients {
  width: 100%;
  background-color: black;
}

.our-clients-info {
  max-width: 71rem;
  padding: 6rem 0;
  margin: auto;
}

.our-clients-info > h1 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
  color: white;
}

.our-clients-info > p {
  text-align: center;
  font-weight: 600;
  padding-top: 1rem;
  margin: 0;
  color: white;
}

.ticketing-features {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  padding: 4rem 2rem;
}

.ticketing-features div {
  color: white;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 3rem 0;
  transition: all 0.2s ease-in;
  border-radius: 5px;
}

.ticketing-features div:hover {
  opacity: 1;
  cursor: pointer;
}
.ticketing-features div.active {
  opacity: 1;
}

/* .ticketing-features div:focus {
  opacity: 1;
  background-color: white;
} */

.ticketing-features img {
  width: 20%;
  margin: auto;
  display: block;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.feature {
  display: grid;
  gap: 3rem;
  max-width: 71rem;
  margin: auto;
  padding: 0 2rem;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

.left-part {
  display: grid;
  gap: 2rem;
}

.left-part h5 {
  color: white;
  font-weight: 600;
}

.left-part h1 {
  color: white;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}

.left-part p {
  color: white;
}

.left-part .cta-btn {
  width: 100%;
  margin: auto;
}

.left-part .cta-btn a {
  text-align: center;
  padding: 1rem;
}

.left-part .learn-more {
  width: 70%;
  background-color: #ff5f8f;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.left-part .learn-more:hover {
  background-color: #eb4c7b;
}

.right-part img {
  pointer-events: none;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .ticketing-features {
    gap: 0;
    padding: 2rem 2rem;
  }

  .ticketing-features div p {
    display: none;
  }

  .ticketing-features img {
    width: 35%;
  }

  .main-img-mobile {
    display: block;
    max-width: 30rem;
    margin: auto;
  }

  .main-img-web {
    display: none;
  }

  .feature {
    grid-template-columns: 1fr;
    gap: 4rem;
    padding: 0 2rem;
  }

  .ticketing-features div {
    padding: 1rem 0;
  }

  .left-part {
    text-align: center;
  }

  .left-part .learn-more {
    max-width: 40rem;
    margin: auto;
  }
}

@media only screen and (max-width: 1050px) {
  .cta-btn {
    margin: auto;
  }
}

@media only screen and (max-width: 650px) {
  .main-page {
    width: 100%;
    height: inherit;
    margin: auto;
    padding: 2rem;
  }

  .our-clients-info h1,
  .left-part h1 {
    font-size: 2rem;
  }

  .main-img-mobile {
    width: 65%;
    margin: auto;
  }

  .left-part .cta-btn {
    width: 80%;
  }

  .left-part .learn-more {
    width: 100%;
    margin: auto;
  }
}
</style>
