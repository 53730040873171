<script setup>
const videos = [
  {
    id: 'myVideo',
    poster: '/tiktok/holi.webp',
    source: '/tiktok/holi.mp4',
  },
  {
    id: 'myVideo',
    poster: '/tiktok/manila-natural-wine-fiesta.webp',
    source: '/tiktok/manila-natural-wine-fiesta.mp4',
  },
  {
    id: 'myVideo',
    poster: '/tiktok/taylor-swift-eras.webp',
    source: '/tiktok/taylor-swift-eras.mp4',
  },
];
</script>

<template>
  <div class="cards">
    <div class="cards-info">
      <section class="container">
        <div
          v-motion
          class="user-container"
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        >
          <div v-for="video in videos" :key="video.id" class="card">
            <video :id="video.id" muted loop controls :poster="video.poster">
              <source :src="video.source" type="video/mp4" />
            </video>

            <div class="icons">
              <a href="https://www.instagram.com/helixpay.ph/">
                <i class="fa-brands fa-instagram" />
              </a>
              <a href="https://www.tiktok.com/@helixpay/">
                <i class="fa-brands fa-tiktok" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.cards {
  background-color: black;
}

.cards-info {
  width: 71rem;
  margin: auto;
}

.container {
  padding: 0 2rem 5rem 2rem;
  justify-content: center;
}

.tiktok-icon {
  border-radius: 20px;
  color: white;
  background: black;
  padding: 15px;
}

.user-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.card {
  border-radius: 5px;
  background-color: none;
  border: none;
  position: relative;
}

.card video {
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.icons {
  top: 20px;
  right: 20px;
  display: grid;
  gap: 1rem;
  position: absolute;
}

.icons i {
  font-size: 1.5rem;
  width: 100%;
  color: white;
  cursor: pointer;
  text-align: center;
  background-color: black;
  border-radius: 5px;
  padding: 10px;
  transition: 0.2s ease-in;
}

.icons i:hover {
  color: #d84f78;
}

@media only screen and (max-width: 1400px) {
  .cards-info {
    width: 60rem;
  }
}

@media (max-width: 1050px) {
  .cards-info {
    width: 40rem;
  }
}

@media (max-width: 1000px) {
  .user-container {
    gap: 2rem;
  }
}

@media (max-width: 800px) {
  #container {
    max-width: 35rem;
  }

  .user-container {
    grid-template-columns: 1fr;
  }

  .card-title div {
    max-width: 20rem;
  }
}

@media (max-width: 650px) {
  .cards-info {
    width: 100%;
  }
}
</style>
